
import { ref, computed } from "vue";
import { useImageProperties } from "@libraryComposables/useImageProperties";
import { useMedia } from "@libraryComposables/useMedia";

export default {
	name: "CoreBlockRows",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const imageComponent = ref(null);

		const {
			croppedSrc,
			mediaBackgroundPosition,
			backgroundMediaFocal,
			backgroundMediaCrop,
			imageSrc: mediaSrc,
			updateCroppedImage,
		} = useImageProperties(props, "backgroundMediaSrc", imageComponent);

		const { isVideo: mediaIsVideo, isImage: mediaIsImg } =
			useMedia(mediaSrc);

		const blockChildren = computed(() => props.settings?.blocks || []);
		const blockVariants = computed(() => {
			return Object.entries(props.settings?.variants || {}).map(
				([key, value]) => {
					return {
						key,
						value,
					};
				}
			);
		});

		const overlayClasses = computed(() => {
			return {
				"row-overlay":
					props.settings?.["overlay-show"] ||
					props.settings?.variants?.["bg-overlay"]
						? props.settings?.variants?.["bg-overlay"]
						: null,
				"row-gradient": props.settings?.["gradient-show"]
					? props.settings?.variants?.["bg-gradient"]
					: null,
			};
		});

		const hasChildren = computed(() => !!props.settings?.blocks?.length);
		const nestedLevelNext = computed(() => props.nestedLevel + 1);

		const bodyClasses = computed(() => {
			const classList = [
				"block__body",
				...blockVariants.value.map((variant) => {
					return `${variant.key}-${variant.value}`;
				}),
			];
			if (!hasChildren.value) {
				classList.push("block__body--empty");
			}
			return [...classList, overlayClasses.value];
		});

		const bodyStyles = computed(() => {
			if (mediaIsImg.value) {
				return {
					"background-image": `url('${croppedSrc.value}')`,
					"background-position": mediaBackgroundPosition.value,
					"background-repeat": "no-repeat",
					"background-size": "cover",
					width: "100%",
					height: props.settings?.rowHeight || "500px",
				};
			}
			return {
				height: props.settings?.rowHeight || "auto",
			};
		});

		const attr = computed(() => {
			return {
				...(props?.settings?.alt && {
					"aria-label": props.settings.alt,
				}),
			};
		});

		const childBlockComponent = computed(() =>
			props.isEditable ? "ForgeControlBlock" : "CoreBlock"
		);

		return {
			imageComponent,
			blockChildren,
			blockVariants,
			overlayClasses,
			hasChildren,
			nestedLevelNext,
			bodyClasses,
			bodyStyles,
			mediaIsImg,
			mediaSrc,
			croppedSrc,
			mediaBackgroundPosition,
			backgroundMediaCrop,
			backgroundMediaFocal,
			attr,
			childBlockComponent,
			mediaIsVideo,
			updateCroppedImage,
		};
	},
};
